
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Actions, Getters, Mutations } from '@/store/enums/TenantEnums';
import { Actions as StoreActions } from '@/store/enums/StoreEnums';
import toasts from '@/utils/toasts';
import { capitalize } from '@/utils/text';
import timeUtil from '@/utils/time';
import moment from 'moment';
import store from '@/store';
import SearchHeader from '@/views/new-design/layout/SearchHeader.vue';
import Navigator from '@/views/new-design/layout/Navigator.vue';
import Card from '@/views/new-design/components/Card.vue';
import Table from '@/views/new-design/components/Table.vue';

export default defineComponent({
  name: 'search-tenant-page',
  props: {
    customClass: {
      type: String,
      default: 'mx-auto',
    },
  },
  mounted() {
    this.setFormSubmitted();
    this.clearForm();
    this.clearLists();

    setTimeout(() => {
      store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);
  },
  components: {
    SearchHeader,
    Navigator,
    Card,
    Table,
  },
  data: () => ({
    loading: false,
    formSubmitted: false,
    errorMessages: [],
    table: {
      headers: [
        { text: 'Name', value: 'first_name' },
        { text: 'DOB', value: 'date_of_birth' },
        { text: 'Email', value: 'email' },
        { text: 'Action', value: 'action', class: 'text-right' },
      ],
      items: [],
    },
  }),
  computed: {
    ...mapGetters({
      searhTenantErrors: Getters.GET_SEARCH_TENANT_ACTION_ERROR,
      allTenants: Getters.GET_ALL_TENANTS,
      tenants: Getters.GET_SEARCH_TENANT_LIST,
    }),
  },
  methods: {
    ...mapActions({
      searchTenant: Actions.SEARCH_TENANT,
    }),
    dateFormat(date: any) {
      return timeUtil.dateFormat(date);
    },
    capitalizeStr(str: any) {
      return capitalize(str);
    },
    clearForm() {
      (this.$refs.searchHeader as any).clear();
    },
    clearLists() {
      if (this.$route.query.ref == 'dashboard') {
        return false;
      }
      store.commit(Mutations.SET_SEARCH_TENANT_LIST, {});
    },
    setFormSubmitted() {
      console.log('setFormSubmitted', this.$route);
      if (
        typeof this.$route.query.ref !== 'undefined' &&
        this.$route.query.ref === 'dashboard'
      ) {
        this.formSubmitted = true;
      }
    },
    handleResetForm() {
      this.errorMessages = [];
      this.loading = false;
      this.formSubmitted = false;
    },
    handleSubmitSearchTenant(payload) {
      if (this.loading) return;

      this.loading = true;
      this.formSubmitted = true;
      this.errorMessages = [];

      const params = {
        first_name: payload.first_name,
        last_name: payload.last_name,
        date_of_birth: payload.date_of_birth
          ? moment(payload.date_of_birth).format('YYYY-MM-DD')
          : null,
      };

      const isValid = this.validateInputs(params);
      if (!isValid) {
        this.loading = false;
        return false;
      }

      this.searchTenant(params)
        .then(() => {
          this.loading = false;
          this.errorMessages = [];
          this.clearForm();
        })
        .catch(() => {
          this.loading = false;
          const { errors, message } = this.searhTenantErrors;
          this.errorMessages = [];
          toasts.error(errors, message);
        });
    },

    validateInputs(fieldValues) {
      for (const [key, value] of Object.entries(fieldValues)) {
        if (typeof value === 'undefined' || value === '' || value === null) {
          this.errorMessages[`${key}`] = `${capitalize(key).replace(
            /_/g,
            ' '
          )} is required`;
        }
      }

      if (Object.values(this.errorMessages).length === 0) {
        return true;
      }

      return false;
    },
    viewProfile(tenant) {
      this.$router.push({
        name: 'view-tenant-reports',
        params: { tenantId: tenant.id },
      });
    },
  },
  watch: {
    tenants(values) {
      if (values.length > 0) {
        this.loading = true;
        this.formSubmitted = true;
      }
    },
  },
  setup() {
    // show page loading
    store.dispatch(StoreActions.ADD_BODY_CLASSNAME, 'page-loading');
  },
});
