
import { defineComponent } from 'vue';
import { Tenant } from '@/models/TenantModel';
import maskUtil from '@/utils/mask';
import dates from '@/utils/dates';

const initialValues = {} as unknown as Tenant;

export default defineComponent({
  name: 'SearchHeader',
  props: {
    loading: { default: false, type: Boolean },
  },
  data() {
    return {
      search: initialValues,
    };
  },
  emits: ['search'],
  methods: {
    searchTenant() {
      this.$emit('search', this.search);
    },
    clear() {
      this.search = {} as any;
    },
  },
  computed: {
    maskDate() {
      return maskUtil.date.aus;
    },
    dateFormat() {
      return dates.defaultFormat;
    },
  },
});
