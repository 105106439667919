
import Template from '@/views/pages/settings/templates/blocks/Template.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    headers: { required: true },
    items: { default: () => [], type: Array },
  },
});
